@font-face {
  font-family: "Source Code Pro Medium";
  src: local('SourceCodePro-Medium.ttf'),
        url('SourceCodePro-Medium.ttf') format('truetype')
}

.Toastify__toast-body{
  white-space: pre-line;
}

.App {
  font-family: "Source Code Pro Medium", sans-serif;
  min-height: 100vh;
  text-align: center;
  color: white;
  /* background-image:linear-gradient(
    to top,
    rgb(38, 140, 153),
    rgba(38, 140, 153, 0.8) 20%,
    rgba(38, 140, 153, 0.6)), url("cool-background.png"); */
  background-size: cover;
  margin: 0;
}

.App-body {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.background {
  position: absolute;
  top: 60px;
  bottom: 60px;
  left: 0;
  z-index: -1;
  height:90% !important;
}

html,
body {
  margin: 0;
  padding: 0;
}
body {
  background-color: black !important;
}

.bi {
  color: white !important;
  font-family: "Source Code Pro Medium", sans-serif;
  text-shadow: 1px 1px grey;
}

.btn-walletManager {
  position: absolute;
  right: 50px;
  top: 100px;
}

.btn-gasManager {
  position: absolute;
  right: 50px;
  top: 200px;
}

.MintContainer {
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
}

.settingsContainer {
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 25px;
}
.settingsContainer p {
  margin-top: 0 !important;
}

.proxyInput {
  height: 250px;
  
}
.modal-header {
  align-self: center
}

.collectionInfo.container {
  width: 60vw;
  margin-bottom: 25px;

}
.collectionInfo .col:not(:last-child) {
  border-right: 1px solid white
}

.collectionInfo .row {
  border-radius: 0.4em;
  border: 1px solid white;
}

.collectionInfo p {
  font-size: xx-small;
  margin-bottom: 0;
}

.traitsInput.container {
  width: 60vw;
  margin-bottom: 25px;
}

.watchSettingsInput.container {
  width: 60vw;
  margin-bottom: 25px;
  display: flex;
  justify-content: center;
}
.modal-content {
  background-color: gray !important;
  color: white
}

.settingsContainer p {
  margin-bottom:0
}
.settingsContainer p:not(:last-child) {
  margin-top: 10px
}
a {
  color: #dc3545 !important;
  text-decoration: none !important;

}

.form-check-input:checked {
  background-color: #dc3545;
  border-color: #dc3545;
}

.fade-enter {
  opacity: 0.01;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0.01;
  transition: opacity 500ms ease-in;
}

.shrink-enter {
  transform: scaleY(0);
  transform-origin: 50% 0%;
}
.shrink-enter-active {
  transform: scale(1.0);
  transition: transform 500ms ease-in;
  transform-origin: 50% 0%;
}
.shrink-exit {
  transform: scaleY(1.0);
  transform-origin: 50% 0%;
}
.shrink-exit-active {
  transform: scaleY(0);
  transform-origin: 50% 0%;
  transition: transform 500ms ease-in;
}
